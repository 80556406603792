import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import style from './Vacancies.module.css';

const Vacancies = ({ vacancies }) => {
  return (
    <main className={style.container}>
      <h1 className={style.header}>Вакансии</h1>
      <div className={style.posts}>
        {vacancies &&
          vacancies.map(({ title, html, requirements, _id }) => (
            <div key={nanoid()} className={style.post}>
              <h2 className={style.title}>
                {title}
                <span className={style.colorLine} />
              </h2>
              <p className={style.desc}>{html}</p>
              {!!requirements.length && (
                <div className={style.tags}>
                  <h3 className={style.subTitle}>Обязательные требования</h3>
                  {requirements.map((tag) => (
                    <span key={nanoid()} className={style.tag}>
                      {tag}
                    </span>
                  ))}
                </div>
              )}
              <Link to={`/profile?vacancy=${_id}`} className={style.actionBtn}>
                Откликнуться
              </Link>
            </div>
          ))}

        {vacancies && vacancies.length === 0 && (
          <div className={style.emptyState}>
            <h2>Пока у нас нет открытых вакансий :(</h2>
            <span>Но Вы все равно можете отправить нам Ваш анкету.</span>
            <Link to="/profile" className={style.actionBtnEmpty}>
              Заполнить анкету
            </Link>
          </div>
        )}
      </div>
    </main>
  );
};

Vacancies.propTypes = {
  vacancies: PropTypes.array,
};

export default Vacancies;
