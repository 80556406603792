import server from '../helpers/httpClient';

const createProfileService = async ({ payload }) => {
  const path = `/profiles`;
  const method = 'post';
  const formData = new FormData();
  for (let key in payload) {
    formData.append(key, payload[key]);
  }
  const headers = {
    'Content-type': 'multipart/form-data',
  };
  const res = await server({ method, path, payload: formData, headers });
  return res.data;
};

const updateProfileService = async ({ payload }) => {
  const path = `/profiles/addPass`;
  const method = 'post';
  const formData = new FormData();
  for (let key in payload) {
    formData.append(key, payload[key]);
  }
  const headers = {
    'Content-type': 'multipart/form-data',
  };
  const res = await server({ method, path, payload: formData, headers });
  return res.data;
};

const getProfileSpecsService = async () => {
  const path = `/specs`;
  const method = 'get';
  const res = await server({ method, path });
  return res.data;
};

export { createProfileService, getProfileSpecsService, updateProfileService };
