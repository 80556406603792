import * as Yup from 'yup';

const isDate = /(0[1-9]|[12][0-9]|3[01])[-](0[1-9]|1[012])[-](19|20)\d\d$/;
const photoFileSize = 1048576;
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

const ProfileValidationSchema = Yup.object().shape({
  uniqueId: Yup.string().required('Обязательное поле'),

  passportNumber: Yup.string().required('Обязательное поле'),

  issueDate: Yup.string()
    .matches(isDate, 'Дата должна быть в формате дд-мм-гггг')
    .required('Обязательное поле'),

  expiryDate: Yup.string()
    .matches(isDate, 'Дата должна быть в формате дд-мм-гггг')
    .required('Обязательное поле'),

  passport: Yup.mixed()
    .required('Обязательное поле')
    .test(
      'fileSize',
      'Размер фото максимум 1МБ',
      (value) => value && value.size <= photoFileSize
    )
    .test(
      'fileFormat',
      'Unsupported Format',
      (value) => value && SUPPORTED_FORMATS.includes(value.type)
    ),
});

export default ProfileValidationSchema;
