import * as Yup from 'yup';

// const isCyrillic = /[\u0400-\u04FF-]/gi;
const isLatin = /[A-Za-z-]/;
const isDate = /(0[1-9]|[12][0-9]|3[01])[-](0[1-9]|1[012])[-](19|20)\d\d$/;
const isPhone = /[+][0-9]/;
const photoFileSize = 1048576;
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];
const isYoutubeLink = /((http(s)?:\/\/)?)(www\.)?((youtube\.com\/)|(youtu.be\/))[\S]+/g;
const socialRegex = /^(?:https?:\/\/)?(?:www\.)?(.+?\.)?(fb.com|fb.me|facebook.com|facebook.me|vk.com|ok.ru|odnoklassniki.ru)(\/)([^\s]{1,})$/gi;

const ProfileValidationSchema = Yup.object().shape({
  // фио дата рождения

  firstName: Yup.string()
    .min(2, 'Не менее двух символов')
    .max(250, 'Максимум 250 символов')
    // .matches(isCyrillic, 'Только кириллические символы'),
    .required('Обязательное поле'),
  lastName: Yup.string()
    .min(2, 'Не менее двух символов')
    .max(250, 'Максимум 250 символов')
    // .matches(isCyrillic, 'Только кириллические символы'),
    .required('Обязательное поле'),
  middleName: Yup.string()
    .min(2, 'Не менее двух символов')
    .max(250, 'Максимум 250 символов')
    // .matches(isCyrillic, 'Только кириллические символы'),
    .required('Обязательное поле'),
  birthDate: Yup.string()
    .matches(isDate, 'Дата должна быть в формате дд-мм-гггг')
    .required('Обязательное поле'),

  // специализация и о себе

  specialization: Yup.mixed().test(
    'string',
    'Обязательное поле',
    (value) => !!value.id
  ),

  about: Yup.string()
    .min(10, 'Минимум 10 символов')
    .max(2000, 'Максимум 2000 символов')
    .required('Обязательное поле'),

  //  параметры тела

  height: Yup.number()
    .min(60, 'Минимум 60см')
    .max(300, 'Максимум 300см')
    .required('Обязательное поле'),

  weigth: Yup.number()
    .min(20, 'Минимум 20кг')
    .max(200, 'Максимум 200кг')
    .required('Обязательное поле'),

  shoeSize: Yup.number()
    .min(0, 'Минимум 0')
    .max(60, 'Максимум 60')
    .required('Обязательное поле'),

  // YOUTUBE

  youtube1: Yup.string()
    .matches(isYoutubeLink, 'Ссылка некорректна')
    .required('Обязательное поле'),
  youtube2: Yup.string().matches(isYoutubeLink, 'Ссылка некорректна'),
  youtube3: Yup.string().matches(isYoutubeLink, 'Ссылка некорректна'),

  // фото

  photoFace: Yup.mixed()
    .required('Обязательное поле')
    .test(
      'fileSize',
      'Размер фото максимум 1МБ',
      (value) => value && value.size <= photoFileSize
    )
    .test(
      'fileFormat',
      'Unsupported Format',
      (value) => value && SUPPORTED_FORMATS.includes(value.type)
    ),
  photoBody: Yup.mixed()
    .required('Обязательное поле')
    .test(
      'fileSize',
      'Размер фото максимум 1МБ',
      (value) => value && value.size <= photoFileSize
    )
    .test(
      'fileFormat',
      'Unsupported Format',
      (value) => value && SUPPORTED_FORMATS.includes(value.type)
    ),

  // контакты

  phone: Yup.string()
    .matches(isPhone, 'Начните с + и дальше используйте цифры')
    .min(2, 'Не менее двух символов')
    .max(30, 'Максимум 30 символов')
    .required('Обязательное поле'),

  email: Yup.string().email('Некорректный email').required('Обязательное поле'),

  facebookID: Yup.string()
    .min(2, 'Не менее двух символов')
    .max(250, 'Максимум 250 символов')
    .matches(
      socialRegex,
      'Ссылка некорректна. Начните с "facebook.com/", или "ok.ru/" или "vk.com/".'
    )
    .required('Обязательное поле'),

  skype: Yup.string()
    .min(2, 'Не менее двух символов')
    .max(250, 'Максимум 250 символов'),

  telegram: Yup.string()
    .min(2, 'Не менее двух символов')
    .max(250, 'Максимум 250 символов'),

  whatsapp: Yup.string()
    .min(2, 'Не менее двух символов')
    .max(250, 'Максимум 250 символов'),

  viber: Yup.string()
    .min(2, 'Не менее двух символов')
    .max(250, 'Максимум 250 символов'),

  wechat: Yup.string()
    .min(2, 'Не менее двух символов')
    .max(250, 'Максимум 250 символов'),

  imo: Yup.string()
    .min(2, 'Не менее двух символов')
    .max(250, 'Максимум 250 символов'),

  botim: Yup.string()
    .min(2, 'Не менее двух символов')
    .max(250, 'Максимум 250 символов'),

  line: Yup.string()
    .min(2, 'Не менее двух символов')
    .max(250, 'Максимум 250 символов'),

  // паспорт

  withPassport: Yup.boolean().required(),

  passportFirstName: Yup.string()
    .min(2, 'Не менее двух символов')
    .max(250, 'Максимум 250 символов')
    .matches(isLatin, 'Только латинские символы')
    .required('Обязательное поле'),

  passportLastName: Yup.string()
    .min(2, 'Не менее двух символов')
    .max(250, 'Максимум 250 символов')
    .matches(isLatin, 'Только латинские символы')
    .required('Обязательное поле'),

  nationality: Yup.mixed().test(
    'string',
    'Обязательное поле',
    (value) => !!value.id
  ),

  passportNumber: Yup.string().when('withPassport', {
    is: true,
    then: Yup.string().required('Обязательное поле'),
  }),

  issueDate: Yup.string()
    .matches(isDate, 'Дата должна быть в формате дд-мм-гггг')
    .when('withPassport', {
      is: true,
      then: Yup.string().required('Обязательное поле'),
    }),

  expiryDate: Yup.string()
    .matches(isDate, 'Дата должна быть в формате дд-мм-гггг')
    .when('withPassport', {
      is: true,
      then: Yup.string().required('Обязательное поле'),
    }),

  passport: Yup.mixed().when('withPassport', {
    is: true,
    then: Yup.mixed()
      .required('Обязательное поле')
      .test(
        'fileSize',
        'Размер фото максимум 1МБ',
        (value) => value && value.size <= photoFileSize
      )
      .test(
        'fileFormat',
        'Unsupported Format',
        (value) => value && SUPPORTED_FORMATS.includes(value.type)
      ),
  }),
});

export default ProfileValidationSchema;
