import PropTypes from 'prop-types';
import cn from 'classnames';

import style from './Input.module.css';

const Input = ({
  formik,
  label,
  type,
  name,
  placeholder,
  uppercase = false,
  lowercase = false,
  disabled = false,
  textarea = false,
}) => {
  const hasError = formik.errors[name] && formik.touched[name];
  return (
    <div className={style.container}>
      <label
        className={cn(style.label, hasError && style.labelError)}
        htmlFor={`form-${name}`}
      >
        {label}
      </label>
      {textarea ? (
        <textarea
          disabled={disabled}
          id={`form-${name}`}
          rows={2}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values[name]}
          name={name}
          className={cn(
            style.textarea,
            uppercase && style.uppercase,
            lowercase && style.lowercase,
            hasError && style.inputError
          )}
          type={type}
          placeholder={placeholder}
        />
      ) : (
        <input
          disabled={disabled}
          value={formik.values[name]}
          id={`form-${name}`}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          name={name}
          className={cn(
            style.input,
            uppercase && style.uppercase,
            lowercase && style.lowercase,
            hasError && style.inputError
          )}
          type={type}
          placeholder={placeholder}
        />
      )}
      <span className={style.error}>{hasError ? formik.errors[name] : ''}</span>
    </div>
  );
};

Input.propTypes = {
  formik: PropTypes.object,
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  uppercase: PropTypes.bool,
  lowercase: PropTypes.bool,
  disabled: PropTypes.bool,
  textarea: PropTypes.bool,
};

export default Input;
