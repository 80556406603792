import cn from 'classnames';
import { nanoid } from 'nanoid';
import { Link } from 'react-router-dom';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';
import PropTypes from 'prop-types';

import style from './Home.module.css';
import logo from '../../img/logo.svg';
import startScreen2 from '../../img/startScreen2.jpg';
import {
  aboutImage,
  building,
  forbs,
  tengHsiung,
  stadium,
  parkLong,
  parkShort,
  mapImg,
  igor,
  vadim,
  andrei,
  kristina,
  natalia,
  fest,
  dolphins,
  shows,
  wave,
  FGlogo,
  teng2,
  show7,
  show1,
  live4,
  artists1,
  placeImages,
} from '../../img/images';

const salaries = [
  { position: 'Артист балета', sum: '1000-1400' },
  { position: 'Актер', sum: '800-1200' },
  { position: 'Артист цирка', sum: '1200-1800' },
  { position: 'Артист оркестра', sum: '1400-1800' },
  { position: 'Вокалистка', sum: '1400-1800' },
];

const Home = ({ hasVacancies }) => {
  return (
    <main className={style.container}>
      <div className={cn(style.start)}>
        <img
          className={style.startBackground}
          src={startScreen2}
          alt="танцовщицы в тумане"
        />
        <div className={style.startWrap}></div>
        <div className={style.startContent}>
          <img src={logo} alt="logo eraart agency" className={style.logo} />
          <p>
            Ищем артистов различных жанров театрального и сценического
            искусства, а также спортсменов для работы в наших шоу на Тайване.
          </p>
          {hasVacancies && (
            <Link to="/vacancies" className={style.actionBtn}>
              Открыты вакансии!
            </Link>
          )}
        </div>
      </div>
      <div className={style.blockContainer}>
        <h2 className={style.blockHeader}>Кто мы</h2>
        <img src={aboutImage} className={style.aboutImageDesk} />
        <div className={style.twoColText}>
          <p>
            Компания Era Art Ltd. основана в январе 2019 года в Болгарии для
            решения задач по реорганизации многолетнего сотрудничества с
            компанией Farglory Ocean Park Co., Ltd., которая входит в группу
            компаний Farglory Group и управляет тематическим парком развлечений
            Farglory Ocean Park.
          </p>
          <img src={aboutImage} className={style.aboutImageMob} />
          <p>
            Основная цель создания компании Era Art Ltd. это расширение
            возможностей сотрудничества между Farglory Ocean Park и
            исполнителями различных видов и жанров сценического искусства как
            европейских стран, так и постсоветских республик.
            <br />
            <br />
            Де-факто наша совместная работа с Farglory Ocean Park ведёт свою
            историю с 2003 года, с первых дней полноценного функционирования
            Департамента Развлечений (Entertainment Department) парка.
          </p>
        </div>
      </div>

      <div className={style.blockContainer}>
        <h2 className={style.blockHeader}>История</h2>
        <div className={style.history}>
          <div className={style.historyBlock}>
            <img src={tengHsiung} />
            <div>
              <h3 className={style.historyTitle}>1944</h3>
              <p className={style.historyText}>
                В 1944 году на западном побережье Тайваня в бедной семье родился
                мальчик по имени Дэн-Сюн.
                <br />
                <br />В 1966 году уже повзрослевший парень Чжао Дэн-Сюн в
                поисках лучшей доли переехал в Тайбэй, столицу Тайваня, где
                занимался случайной работой, чтобы накопить хоть немного денег и
                поставлять цемент строительным фирмам.
              </p>
            </div>
          </div>

          <div className={style.historyBlock}>
            <img src={building} />
            <div>
              <h3 className={style.historyTitle}>1969</h3>
              <p className={style.historyText}>
                В 1969 году, взяв небольшой кредит, Чжао Дэн-Сюн основал с нуля
                компанию, которая и считается родоначальником Farglory Group.
                Работа компании началась со строительства фабрик и офисов.
              </p>
            </div>
          </div>

          <div className={style.historyBlock}>
            <img src={stadium} />
            <div>
              <h3 className={style.historyTitle}>1978</h3>
              <p className={style.historyText}>
                В 1978 году Чжао Дэн-Сюн основал компанию Farglory Land, одного
                из крупнейших девелоперов Тайваня, и до сих пор является её
                крупнейшим акционером. Постоянно наращивая обороты, от
                проектирования домов нового поколения, до развития нового
                поколения городов, Чжао Дэн-Сюн стал известным на Тайване
                градостроителем.
              </p>
            </div>
          </div>

          <div className={style.historyBlock}>
            <img src={forbs} />
            <div>
              <h3 className={style.historyTitle}>2003</h3>
              <p className={style.historyText}>
                C 2003 года начал полноценно функционировать Департамент
                Развлечений (Entertainment Department) океанического парка
                Hualien Ocean Park, ныне тематического парка развлечений
                Farglory Ocean Park. Владеет и управляет парком компания
                Farglory Ocean Park Co., Ltd., входящая в группу компаний
                Farglory Group.
              </p>
            </div>
          </div>

          <div className={style.historyBlock}>
            {/* <img src={forbs} /> */}
            <div />
            <div>
              <h3 className={style.historyTitle}>Сегодня</h3>
              <p className={style.historyText}>
                Farglory Land на сегодняшний день является флагманским
                подразделением уже целой группы компаний Farglory Group,
                интересы которых включают девелопмент недвижимости,
                строительство, страхование и финансы, авиагрузовую логистику,
                рекреацию и гостеприимство, спортивные комплексы и
                бизнес-центры, здравоохранение, благотворительность и различные
                зарубежные инвестиции. С нуля Farglory Group выросла до своей
                текущей стоимости почти в 600 миллиардов долларов.
              </p>
            </div>
          </div>

          <div className={style.historyLine} />
        </div>
      </div>

      <div className={style.blockContainer}>
        <h2 className={style.blockHeader}>Farglory Group</h2>
        <div className={style.twoColText}>
          <p>
            Господин Чжао хорошо известен как “король мегаполисов и промышленных
            высокотехнологичных парков” на Тайване. В последние годы он стал
            пионером концепции &quot;Дома следующего поколения&quot;, которая
            объединяет интеллектуальные функции и зеленые технологии в
            разработках городских кварталов Тайбея.
            <br />
            <br />
            Его успех основан на основной философии всегда “заботиться о
            потребностях клиента”, и он сделал это убеждение центральным в
            культуре Farglory Group для ее операций и сотрудников.
          </p>
          <img src={FGlogo} className={style.twoColImg} />
        </div>

        <div className={cn(style.twoColText, style.reverse)}>
          <img src={teng2} className={style.twoColImg} />
          <p>
            Господин Чжао лично посещает текущие строительные площадки каждую
            субботу и делает это в обязательном порядке уже более 50 лет,
            проверяя каждую деталь, независимо от того, идет ли дождь или светит
            солнце.
            <br /> <br />
            Господин Чжао Дэн-Сюн имеет троих детей. В 2014 году уже его старший
            сын Вэнь-Чжиа Чжао (Wen-Chia Chao, 趙 文 嘉 ), также известный как
            Фрэнк, стал помогать отцу, заняв пост председателя Farglory Land,
            флагманской компании Farglory Group.
            <a
              href="https://www.farglory.com.tw/en"
              target="_blank"
              rel="noreferrer"
              className={style.link}
            >
              Farglory Group
              <span className={style.linkArrow} />
            </a>
          </p>
        </div>
      </div>

      <div className={style.block}>
        <img src={parkLong} className={style.fopParkLongImg} />
        <img src={parkShort} className={style.fopParkShortImg} />
        <div className={cn(style.blockContainer, style.fopBlock)}>
          <h2 className={style.blockHeader}>Farglory Ocean Park</h2>
          <div className={style.twoColText}>
            <p>
              Farglory Ocean Park – это первый морской тематический парк на
              Тайване и один из самых известных среди развлекательных парков
              острова. Farglory Ocean Park управляется компанией Farglory Ocean
              Park Co., Ltd., которая входит в группу компаний Farglory Group.
              Парк уютно расположился на восточном побережье острова Тайвань в
              живописном месте на самом берегу Тихого океана, недалеко от города
              Хуалянь (Hualien).
              <br />
              <br />
              По своему обустройству Farglory Ocean Park напоминает британский
              морской пирс времен девятнадцатого века. Все аттракционы,
              анимация, шоу-программы, кафе, рестораны и сувенирные лавки,
              функционирующие на территории парка, подчинены единой теме
              мирового океана и его обитателей.
              <br />
              <br />
              На верхней площадке парка красуется величественный замок, словно
              оставленный в наследство этому красивому месту средневековьем. В
              этом замке находится Crystal Castle Theater, основное место работы
              наших артистов.
              <a
                href="https://www.farglory-oceanpark.com.tw/en"
                target="_blank"
                rel="noreferrer"
                className={style.link}
              >
                Farglory Ocean Park
                <span className={style.linkArrow} />
              </a>
              <a
                href="https://goo.gl/maps/Lmg6CWuUyStMe5vJ9"
                target="_blank"
                rel="noreferrer"
                className={style.link}
              >
                Map
                <span className={style.linkArrow} />
              </a>
            </p>

            <div className={style.fopMap}>
              <div className={style.fopPointer}>
                <div />
                <span>
                  Farglory
                  <br />
                  Ocean <br />
                  Park
                </span>
              </div>
              <img src={mapImg} />
            </div>
          </div>
          <div className={style.fopAdvs}>
            <div>
              <img src={shows} />
              <span>
                Непрерывно сменяющие друг друга с 2003 года феерические
                представления
              </span>
            </div>
            <div>
              <img src={fest} />
              <span>Периодические парады артистов на территории парка</span>
            </div>
            <div>
              <img src={wave} />
              <span>Летние шоу с водой на открытой сцене</span>
            </div>
            <div>
              <img src={dolphins} />
              <span>
                Водные шоу в Театре Дельфинов (Dolphin Theater) и аквариуме
              </span>
            </div>
          </div>

          <div className={cn(style.twoColText, style.reverse)}>
            <img src={show7} className={style.twoColImg} />
            <p>
              Театр привлекает масштабными постановками, объединяющими цирковые
              номера, театр и танец в единое целое. В в сочетании с живым
              исполнением музыки нашим оркестром и самобытным вокалом,
              причудливым дизайном костюмов и характерной хореографией создаёт
              волшебный мир наших шоу.
              <br />
              <br />
              Сцена Crystal Castle Theater располагает большими техническими
              возможностями, что позволяет воплощать самые смелые идеи.
              Тайваньские зрители проявляют огромный интерес к нашим фантазийным
              постановкам.
            </p>
          </div>
        </div>
      </div>

      <div className={style.blockContainer}>
        <h2 className={style.blockHeader}>Команда</h2>
        <div className={style.team}>
          <div className={style.lead}>
            <img src={vadim} />
            <span className={style.leadName}>Вадим Проскуряков</span>
            <span className={style.leadPosition}>
              Артист. Уполномоченный представитель Era Art в Farglory Ocean Park
            </span>
          </div>
          <div className={style.lead}>
            <img src={natalia} />
            <span className={style.leadName}>Наталья Стрелковская</span>
            <span className={style.leadPosition}>
              Артистка балета, хореограф и репетитор
            </span>
          </div>
          <div className={style.lead}>
            <img src={andrei} />
            <span className={style.leadName}>Андрей Винцерский</span>
            <span className={style.leadPosition}>
              Артист и руководитель оркестра, композитор и аранжировщик
            </span>
          </div>
          <div className={style.lead}>
            <img src={igor} />
            <span className={style.leadName}>Игорь Чичин</span>
            <span className={style.leadPosition}>Генеральный директор</span>
          </div>
          <div className={style.lead}>
            <img src={kristina} />
            <span className={style.leadName}>Кристина Эриксон</span>
            <span className={style.leadPosition}>Директор</span>
          </div>
          <div />
        </div>
      </div>

      <div className={cn(style.blockContainer, style.artistsBlock)}>
        <h2 className={style.blockHeader}>Артисты</h2>
        <div className={style.twoColText}>
          <p>
            За долгие годы совместной работы с парком над различными шоу,
            поставленными на сцене театра Crystal Castle Theater, в них приняли
            участие артисты из разных уголков России (от Санкт-Петербурга и
            Северной Осетии до городов Сибири), из Украины и Беларуси, Грузии и
            Армении, Молдовы, Казахстана, Узбекистана, Киргизии, Румынии,
            Венгрии и Болгарии.
          </p>
          <img src={show1} className={style.twoColImg} />
        </div>
        <div className={style.twoColText}>
          <img src={artists1} className={style.twoColImg} />
          <p>
            Многие артисты сотрудничают с нами на протяжении долгих лет: кто-то
            работает в парке годами, прерываясь только на время отпусков, кто-то
            возвращается вновь и вновь, спустя какой-то период времени. Немало
            тех, кто проработал с нами непрерывно или суммарно уже более 7 лет.
            Эти артисты уверены в нас, а мы доверяем им, подчёркивая свою
            благодарность и уважение.
            <br />
            <br />
            Целый ряд «наших бывших» добились значительных успехов в своей
            профессии, участвуя в последствии в значимых проектах мирового
            уровня, завоёвывая признание и престижные награды в различных
            областях циркового искусства, кино, театра и спорта. Для этих людей
            Crystal Castle Theater послужил надёжной площадкой в
            совершенствовании их профессионального мастерства.
          </p>
        </div>
        <div className={cn(style.twoColText, style.reverse)}>
          <p>
            Функции управления всем рабочим процессом, связанным непосредственно
            с нашими артистами, в Farglory Ocean Park возложены на Департамент
            Развлечений (Entertainment Department). Персонал (staff)
            Департамента Развлечений руководит всеми артистами и занимается их
            рабочими и бытовыми вопросами.
            <br />
            <br />
            Все указания и распоряжения Департамент Развлечений доводит до
            артистов через уполномоченного Era Art представителя, работающего в
            парке.
          </p>
          <img src={live4} className={style.twoColImg} />
        </div>
      </div>

      <div className={cn(style.block, style.galleryBlock)}>
        <h2 className={style.galleryBlockHeader}>Условия размещения</h2>
        <div className={style.photos}>
          {placeImages.map((image) => (
            <img key={nanoid()} src={image} className={style.photo} />
          ))}
        </div>
      </div>

      <div className={cn(style.blockContainer, style.accBlock)}>
        <Accordion allowMultipleExpanded>
          <AccordionItem uuid="1">
            <AccordionItemHeading className={style.accHead}>
              <AccordionItemButton className={style.accBtn}>
                <span>Зарплата</span>
                <AccordionItemState>
                  {({ expanded }) => (
                    <div
                      className={cn(style.arrow, expanded && style.arrowUp)}
                    />
                  )}
                </AccordionItemState>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={style.accPanel}>
              <div className={style.salary}>
                {salaries.map((item) => (
                  <div className={style.cell} key={nanoid()}>
                    <span className={style.artPosition}>{item.position}</span>
                    <span className={style.sum}>{item.sum} USD/мес</span>
                  </div>
                ))}
              </div>
              <div className={style.panelText}>
                <span>
                  Зарплата оговаривается с каждым артистом индивидуально.
                </span>
                <span>
                  Существует система дополнительного премирования артиста по
                  результатам отработанного месяца.
                </span>
                <span>
                  Периодически появляется возможность дополнительных работ
                  (extra), которые может организовать и оплатить дополнительно
                  только Департамент Развлечений парка.
                </span>
                <span>
                  Репетиционный период оплачивается по предварительной
                  договорённости.
                </span>
                <span>
                  Дополнительно каждую неделю выдаются деньги на питание из
                  расчета:
                </span>
                <div>
                  150 TWD на выходной день артиста / 100 TWD на рабочий день
                  артиста
                </div>

                <span className={style.smallText}>
                  * TWD – тайваньский новый доллар, обозначается NTD или NT$
                </span>
                <span className={style.smallText}>
                  ** 1 USD приблизительно равен 29-30 TWD
                </span>
              </div>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem uuid="2">
            <AccordionItemHeading className={style.accHead}>
              <AccordionItemButton className={style.accBtn}>
                Чем обеспечивает парк
                <AccordionItemState>
                  {({ expanded }) => (
                    <div
                      className={cn(style.arrow, expanded && style.arrowUp)}
                    />
                  )}
                </AccordionItemState>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={style.accPanel}>
              <ul className={style.panelText}>
                <li>питанием в рабочее время в столовой для стаффа;</li>
                <li> питьевой водой;</li>
                <li>
                  униформой для передвижения по территории парка (майки,
                  рубашки, куртки, бейсболки, комбинезоны и т.п.);
                </li>
                <li>
                  формой для репетиций («джазовки», «чешки», носки, шорты, майки
                  и т.п.);
                </li>
                <li> гримом и косметикой для шоу;</li>
                <li>
                  костюмами для шоу, реквизитом и музыкальными инструментами (по
                  предварительной договорённости);
                </li>
                <li>возможностью репетировать (по установленному графику);</li>
                <li>
                  доставкой в рабочие дни на работу и обратно на комфортабельном
                  автобусе;
                </li>
              </ul>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem uuid="3">
            <AccordionItemHeading className={style.accHead}>
              <AccordionItemButton className={style.accBtn}>
                Что оплачивает парк
                <AccordionItemState>
                  {({ expanded }) => (
                    <div
                      className={cn(style.arrow, expanded && style.arrowUp)}
                    />
                  )}
                </AccordionItemState>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={style.accPanel}>
              <ul className={style.panelText}>
                <li>
                  оформление Разрешения на работу артиста в Китайской республике
                  Тайвань;
                </li>
                <li>оформление въездной визы артиста;</li>
                <li>оформление и ежегодное продление ARC card артиста;</li>
                <li>
                  перелёт артиста “страна проживания – Тайвань” и обратно (если
                  артист полностью отработал договорный период);
                </li>
                <li>
                  отпуск артиста - 17 дней, проводимый вне Тайваня (после 12
                  месяцев, но при продлении на следующие 12);
                </li>
                <li>
                  перелёт артиста “Тайвань – место отпуска” и обратно (не дороже
                  текущей стоимости перелёта артиста из Тайваня в страну его
                  проживания и обратно);
                </li>
              </ul>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem uuid="4">
            <AccordionItemHeading className={style.accHead}>
              <AccordionItemButton className={style.accBtn}>
                Работа и быт
                <AccordionItemState>
                  {({ expanded }) => (
                    <div
                      className={cn(style.arrow, expanded && style.arrowUp)}
                    />
                  )}
                </AccordionItemState>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={style.accPanel}>
              <ul className={style.panelText}>
                <li>Минимум один выходной день в неделю.</li>
                <li>
                  Минимальный срок работы в парке – 12 месяцев (если иной срок
                  не оговаривался предварительно).
                </li>
                <li>
                  Каждый артист в течение рабочего дня принимает участие в 3 шоу
                  на сцене и в параде, что суммарно не должно превышать 150
                  минут в день (не включается подготовка к представлению и
                  репетиции).
                </li>
                <li>Длительность шоу в Crystal Castle Theater – 40-50 мин.</li>
                <li>
                  В период празднования Китайского Нового года (7-10 дней в
                  году) возможно участие в 5 представлениях (с предоставлением
                  дополнительного питания).
                </li>
                <li>
                  Проживают артисты в общежитии для сотрудников Farglory Ocean
                  Park по 2 человека в комнате. Общежитие находится в городе
                  Хуалянь (Hualien), в пешей доступности к центру города и
                  благоустроенному парку на берегу океана.
                </li>
                <li>
                  В общежитии, где проживают артисты, организована общая кухня.
                </li>
              </ul>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
        <div className={style.disclaimer}>
          Подробное описание всех условий работы и быта, а также прав и
          обязанностей артиста и парка, будет изложено в Рабочем Соглашении
          между Farglory Ocean Park (Hualien, Taiwan) и артистом.
        </div>
      </div>
    </main>
  );
};

Home.propTypes = {
  hasVacancies: PropTypes.bool,
};

export default Home;
