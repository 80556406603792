import server from '../helpers/httpClient';

const getVacanciesService = async () => {
  const path = `/vacancies/active`;
  const method = 'get';
  const res = await server({ method, path });
  return res.data;
};

export { getVacanciesService };
