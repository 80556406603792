import fb from '../img/facebook.svg';
import insta from '../img/instagram.svg';
import y from '../img/youtube.svg';
import tg from '../img/telegram.svg';

const socials = [
  {
    name: 'instagram',
    href: process.env.REACT_APP_INSTA_URL,
    logo: insta,
  },
  {
    name: 'facebook',
    href: process.env.REACT_APP_FACEBOOK_URL,
    logo: fb,
  },
  {
    name: 'youtube',
    href: process.env.REACT_APP_YOUTUBE_URL,
    logo: y,
  },
  {
    name: 'telegram',
    href: process.env.REACT_APP_TELEGRAM_URL,
    logo: tg,
  },
];

const routes = [
  {
    name: 'Главная',
    path: '/',
  },
  {
    name: 'Анкета',
    path: '/profile',
  },
  {
    name: 'Вакансии',
    path: '/vacancies',
  },
  {
    name: 'Галерея',
    path: '/gallery',
  },
];

const contacts = [
  {
    name: 'email',
    contact: process.env.REACT_APP_EMAIL,
    href: `mailto:${process.env.REACT_APP_EMAIL}?subject=Question from website`,
  },
  {
    name: 'phone',
    contact: process.env.REACT_APP_PHONE,
    href: `tel:${process.env.REACT_APP_PHONE}`,
  },
  {
    name: 'wire',
    contact: process.env.REACT_APP_WIRE,
  },
  {
    name: 'skype',
    contact: process.env.REACT_APP_SKYPE,
  },
  {
    name: 'wechat',
    contact: process.env.REACT_APP_WECHAT,
  },
];

export { contacts, socials, routes };
