const validateCaptcha = () => {
  return new Promise((res, rej) => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(process.env.REACT_APP_GOOGLE_RECAPTCHA, { action: 'submit' })
        .then(
          (token) => {
            return res(token);
          },
          () => rej(new Error('Ошибка в Google RECAPTCHA'))
        );
    });
  });
};

export default validateCaptcha;
