import PropTypes from 'prop-types';
import AliceCarousel from 'react-alice-carousel';

import './alice-carousel.css';
import style from './ImageGallery.module.css';

import { showImages } from '../../img/images';

const responsive = {
  0: { items: 1 },
};

const items = showImages.map((image, index) => (
  <img key={index} data-value={index} className={style.image} src={image} />
));

const ImageGallery = ({ close, isOpen }) => {
  return (
    <div className={isOpen ? style.container : style.hide}>
      <button onClick={close} className={style.close}>
        Закрыть
      </button>
      <AliceCarousel
        disableDotsControls
        infinite
        items={items}
        responsive={responsive}
      />
      ;
    </div>
  );
};

ImageGallery.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default ImageGallery;
