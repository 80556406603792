import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { useState } from 'react';

import ProfilePassportValidationSchema from './ProfilePassportValidationSchema';
import { updateProfileService } from '../../server/profileAPI';
import Input from '../../components/Input/Input';
import validateCaptcha from '../../helpers/recaptcha';
import PhotoUpload from '../../components/PhotoUpload/PhotoUpload';

import style from './ProfilePassport.module.css';
import 'react-toastify/dist/ReactToastify.css';

const Profile = () => {
  const [isDisabled, setDisabled] = useState(false);

  const history = useHistory();

  const query = new URLSearchParams(useLocation().search);
  const id = query.get('id');
  const hasAuthParams = id;

  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      uniqueId: hasAuthParams ? id : '',
      passportNumber: '',
      expiryDate: '',
      issueDate: '',
      passport: null,
    },
    validationSchema: ProfilePassportValidationSchema,
  });

  const handleReset = () => {
    formik.resetForm();
    formik.validateForm();
  };

  const handleSubmit = async () => {
    if (!formik.isValid) {
      formik.handleSubmit();
      toast.error('Анкета заполнена некорректно');
      return;
    }
    try {
      setDisabled(true);
      const recaptchaToken = await validateCaptcha();
      await updateProfileService({
        payload: {
          ...formik.values,
          recaptchaToken,
        },
      });
      handleReset();
      setDisabled(false);
      history.push('/');
      toast.success('Паспорт добавлен. До скорой связи!');
    } catch (e) {
      console.error(e);
      setDisabled(false);
      toast.error(
        (e.data && e.data.message && e.data.message) || 'Что-то пошло не так...'
      );
    }
  };

  return (
    <main className={style.main}>
      <div className={style.head}>
        <h1 className={style.header}>Паспорт</h1>
        <Link to="/profile" className={style.actionBtn}>
          Анкета
        </Link>
      </div>
      <p className={style.desc}>
        Здесь Вы можете добавить паспортные данные к поданной анкете. Для этого
        Вам необходимо воспользоваться email, который мы выслали Вам после
        подачи анкеты.
        <br />
        Если наш email потерялся, напишите в наш бот{' '}
        <a href="https://t.me/eraartagencybot" target="_blank" rel="noreferrer">
          @EraArtAgencyBot
        </a>{' '}
        и мы вышлем email заново.
        <br />
        Если остались вопросы - пишите в наш бот.
      </p>
      <form className={style.form}>
        <div className={style.field}>
          <h2 className={style.fieldName}>Данные анкеты</h2>
          <Input
            name="uniqueId"
            formik={formik}
            label="Уникальный номер"
            type="text"
            placeholder="lkjsdbhg394thgu"
            disabled={hasAuthParams}
          />
          <div className={style.inputs}></div>
        </div>
        <div className={style.field}>
          <h2 className={style.fieldName}>Паспортные данные</h2>
          <div className={style.inputs}>
            <Input
              name="issueDate"
              formik={formik}
              label="Дaта выдачи"
              type="text"
              placeholder="01-01-1991"
            />
            <Input
              name="expiryDate"
              formik={formik}
              label="Срок действия (до)"
              type="text"
              placeholder="01-01-1991"
            />
            <Input
              name="passportNumber"
              formik={formik}
              label="Номер паспорта"
              type="text"
              placeholder="ABC123"
              uppercase
            />
          </div>
        </div>
        <div className={style.field}>
          <h2 className={style.fieldName}>Фото или скан паспорта</h2>
          <PhotoUpload
            name="passport"
            formik={formik}
            handleFile={(file) => formik.setFieldValue('passport', file)}
          />
        </div>
      </form>

      <div className={style.submitWrapper}>
        <button
          type="submit"
          onClick={handleSubmit}
          className={style.actionBtn}
          disabled={isDisabled}
        >
          {isDisabled ? 'Отправка...' : 'Отправить'}
          {isDisabled && (
            <div className={style.loader}>
              <div />
            </div>
          )}
        </button>
        <p className={style.disclaimer}>
          Ваши данные хранятся на защищенном сервере и используются ТОЛЬКО для
          оформления документов
        </p>
      </div>
    </main>
  );
};

export default Profile;
