import { useState } from 'react';
import cn from 'classnames';
import { Link, useRouteMatch } from 'react-router-dom';
import { nanoid } from 'nanoid';

import { useScrollPosition } from '../../helpers/useScrollPosition';

import { routes } from '../../helpers/constants';
import logo from '../../img/logo.svg';
import Menu from '../Menu/Menu';
import style from './Header.module.css';

const Header = () => {
  const [isOpenMenu, setOpenMenu] = useState(false);
  const handleMenuToggle = () => setOpenMenu(!isOpenMenu);
  const [isShowHeader, setShowHeader] = useState(false);

  const isHideHeader =
    useRouteMatch({
      path: '/',
      exact: true,
    }) &&
    !isShowHeader &&
    !isOpenMenu;

  useScrollPosition(
    ({ currPos }) => {
      const vh =
        (Math.max(
          document.documentElement.clientHeight || 0,
          window.innerHeight || 0
        ) /
          3) *
        -1;
      const isShow = currPos.y < vh;
      if (isShow !== isShowHeader) setShowHeader(isShow);
    },
    [isShowHeader]
  );

  const handleScroll = () => {
    window.scroll({
      top: document.body.offsetHeight,
      left: 0,
      behavior: 'smooth',
    });
  };
  return (
    <>
      <nav
        className={cn(
          style.navbar,
          isOpenMenu && style.navbarOpenMenu,
          isHideHeader && style.hideHeader
        )}
      >
        <Link to="/" onClick={() => setOpenMenu(false)}>
          <img src={logo} alt="eraart logo" className={style.logoImg} />
        </Link>

        <div className={style.links}>
          {routes.map(({ path, name }) => (
            <Link
              to={path}
              className={cn(
                style.link,
                useRouteMatch({
                  path,
                }) && style.linkActive
              )}
              key={nanoid()}
            >
              <span>{name}</span>
            </Link>
          ))}
          <button className={style.link} onClick={handleScroll}>
            Контакты
          </button>
        </div>
        <button
          className={cn(style.menuBtn, isOpenMenu && style.menuOpen)}
          onClick={handleMenuToggle}
        >
          <div className={style.shortLine}></div>
          <div className={style.longLine}></div>
        </button>
      </nav>
      <Menu closeMenu={() => setOpenMenu(false)} isOpenMenu={isOpenMenu} />
    </>
  );
};

export default Header;
