import axios from 'axios';

// eslint-disable-next-line no-undef
const apiUrl = process.env.REACT_APP_API_URL;
const defaultContentHeader = { 'Content-Type': 'application/json' };
const server = async ({
  method,
  path,
  payload,
  headers,
  responseType = 'json',
}) => {
  const reqHeaders = headers ? headers : defaultContentHeader;
  try {
    const result = await axios({
      method: method,
      url: apiUrl + path,
      data: payload,
      headers: {
        common: {
          'X-Requested-With': 'XMLHttpRequest',
        },
        ...reqHeaders,
      },
      responseType,
    });

    return result;
  } catch (err) {
    throw err.response || err;
  }
};

export default server;
