import YouTube from 'react-youtube';
import cn from 'classnames';
import { useState } from 'react';

import ImageGallery from '../../components/ImageGallery/ImageGallery';

import { showImages } from '../../img/images';

import style from './Gallery.module.css';
import { nanoid } from 'nanoid';

const videoTitle = [
  'видео',
  'видео',
  'видео',
  'видео',
  'видео',
  'видео',
  'видео',
  'видео',
  'видео',
  'видео',
  'видео',
  'видео',
  'видео',
  'видео',
  'видео',
  'видео',
  'видео',
];
const photoTitle = [
  'фото',
  'фото',
  'фото',
  'фото',
  'фото',
  'фото',
  'фото',
  'фото',
  'фото',
  'фото',
  'фото',
  'фото',
  'фото',
  'фото',
  'фото',
  'фото',
  'фото',
];

const videos = [
  {
    id: 'wIlrz4AJPCo',
    isSelect: false,
    isActive: false,
  },
  {
    id: 'qVPrKydjjDc',
    isSelect: false,
    isActive: false,
  },
  {
    id: 'k-uMXYfKbNY',
    isSelect: false,
    isActive: false,
  },
  {
    id: 'rd1lgB-1QBU',
    isSelect: false,
    isActive: false,
  },
  {
    id: 'uBsW424P8Fw',
    isSelect: false,
    isActive: false,
  },
  {
    id: 'JnWDDzIVk38',
    isSelect: true,
    isActive: false,
  },
];

const Gallery = () => {
  const [fullScreen, setFullScreen] = useState(false);
  return (
    <main className={style.container}>
      <ImageGallery isOpen={fullScreen} close={() => setFullScreen(false)} />
      <h1 className={style.header}>Галерея</h1>

      <div className={style.scrollContainer}>
        <div className={style.titleLine}>
          {photoTitle.map((title) => (
            <span key={nanoid()}>{title}</span>
          ))}
        </div>
        <div className={style.photos}>
          {showImages.map((image) => (
            <img
              key={nanoid()}
              onClick={() => setFullScreen(true)}
              src={image}
              className={style.photo}
            />
          ))}
        </div>
        <div className={style.titleLine}>
          {photoTitle.map((title) => (
            <span key={nanoid()}>{title}</span>
          ))}
        </div>
      </div>
      <div className={style.scrollContainer}>
        <div className={style.titleLine}>
          {videoTitle.map((title) => (
            <span key={nanoid()}>{title}</span>
          ))}
        </div>
        <div className={style.videos}>
          {videos.map(({ id }, index) => (
            <div key={index} className={cn(style.video)}>
              <YouTube className={cn(style.player)} videoId={id} />
            </div>
          ))}
        </div>
        <div className={style.titleLine}>
          {videoTitle.map((title) => (
            <span key={nanoid()}>{title}</span>
          ))}
        </div>
      </div>
    </main>
  );
};

export default Gallery;
