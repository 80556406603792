import { nanoid } from 'nanoid';

import { socials, contacts } from '../../helpers/constants';

import style from './Footer.module.css';

const Footer = () => {
  return (
    <footer className={style.container}>
      <div className={style.wrapper}>
        <h2 className={style.header}>Мы на связи</h2>
        <div className={style.contacts}>
          {contacts.map(({ name, contact, href }) => (
            <div key={nanoid()} className={style.contact}>
              <span className={style.label}>{name}</span>
              {href ? (
                <a href={href} className={style.data}>
                  {contact}
                </a>
              ) : (
                <span className={style.data}>{contact}</span>
              )}
            </div>
          ))}
          {/* <button className={style.subscrDesk}>подписаться на новости</button> */}
        </div>
        {/* <button className={style.subscrMobile}>подписаться на новости</button> */}
        <div className={style.socials}>
          {socials.map(({ name, href, logo }) => (
            <a
              key={nanoid()}
              href={href}
              target="_blank"
              rel="noreferrer"
              className={style.socialLink}
              title={name}
            >
              <img src={logo} alt={name} />
            </a>
          ))}
        </div>
        <p className={style.recaptcha}>
          Protected by Google reCAPTCHA.
          <br />
          <a href="https://policies.google.com/privacy">Privacy Policy</a>{' '}
          <a href="https://policies.google.com/terms">Terms of Service</a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
