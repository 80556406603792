import { Link } from 'react-router-dom';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { routes } from '../../helpers/constants';

import style from './Menu.module.css';

const Menu = ({ isOpenMenu, closeMenu }) => {
  const handleScroll = () => {
    closeMenu();
    window.scroll({
      top: document.body.offsetHeight,
      left: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className={cn(style.container, !isOpenMenu && style.hideMenu)}>
      <nav className={style.links}>
        {routes.map(({ name, path }) => (
          <Link
            key={nanoid()}
            to={path}
            className={style.link}
            onClick={closeMenu}
          >
            {name}
          </Link>
        ))}
        <button className={style.link} onClick={handleScroll}>
          Контакты
        </button>
      </nav>
    </div>
  );
};

Menu.propTypes = {
  isOpenMenu: PropTypes.bool.isRequired,
  closeMenu: PropTypes.func.isRequired,
};

export default Menu;
