import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';

import style from './ModalPassport.module.css';

Modal.setAppElement('#root');

const ModalPassport = ({ setWithPassport, withPassport }) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const toggleModal = () => {
    if (withPassport) {
      setIsOpen(true);
    } else {
      setWithPassport(true);
    }
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <button type="button" onClick={toggleModal} className={style.openBtn}>
        {withPassport ? 'Не заполнять' : 'Заполнить'}
      </button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        className={style.container}
      >
        <h2>Паспортные данные</h2>
        <button className={style.closeBtn} type="button" onClick={closeModal} />
        <div className={style.desc}>
          Ваши паспортные данные необходимы нам для оформления документов на
          работу, а также оформления визы. Если Вы не хотите сообщать нам эту
          информацию до решения о сотрудничестве, Вы без проблем сможете дослать
          нам паспорт позже. Инструкцию по добавлению паспорта мы вышлем Вам на
          email указанный в анкете.
        </div>
        <div className={style.btns}>
          <button
            type="button"
            onClick={() => {
              setWithPassport(false);
              closeModal();
            }}
            className={style.btnOutline}
          >
            Вышлю позже
          </button>
          <button type="button" onClick={closeModal} className={style.btn}>
            Заполнить
          </button>
        </div>
      </Modal>
    </>
  );
};

ModalPassport.propTypes = {
  setWithPassport: PropTypes.func,
  withPassport: PropTypes.bool,
};

export default ModalPassport;
