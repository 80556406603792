import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import cn from 'classnames';
import { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';

import ProfileValidationSchema from './ProfileValidationSchema';
import {
  getProfileSpecsService,
  createProfileService,
} from '../../server/profileAPI';
import Input from '../../components/Input/Input';
import Select from '../../components/Select/Select';
import ModalPassport from '../../components/ModalPassport/ModalPassport';
import PhotoUpload from '../../components/PhotoUpload/PhotoUpload';
import validateCaptcha from '../../helpers/recaptcha';
import countries from '../../helpers/countries';

import style from './Profile.module.css';

const Profile = () => {
  const history = useHistory();
  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      withPassport: true,
      photoFace: null,
      photoBody: null,
      passport: null,
      firstName: '',
      lastName: '',
      middleName: '',
      passportFirstName: '',
      passportLastName: '',
      nationality: {},
      passportNumber: '',
      birthDate: '',
      expiryDate: '',
      issueDate: '',
      specialization: {},
      about: '',
      phone: '',
      email: '',
      skype: '',
      telegram: '',
      whatsapp: '',
      viber: '',
      facebookID: '',
      imo: '',
      botim: '',
      line: '',
      wechat: '',
      weigth: '',
      height: '',
      shoeSize: '',
      youtube1: '',
      youtube2: '',
      youtube3: '',
    },
    validationSchema: ProfileValidationSchema,
  });

  const [specs, setSpecs] = useState([]);
  const [isDisabled, setDisabled] = useState(false);

  const getSpecs = async () => {
    try {
      const data = await getProfileSpecsService();
      const prepData = data.map(({ _id, name }) => ({ id: _id, value: name }));
      setSpecs(prepData);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getSpecs();
  }, []);

  const handleSubmit = async () => {
    if (!formik.isValid) {
      formik.handleSubmit();
      toast.error('Анкета заполнена некорректно');
      return;
    }
    try {
      setDisabled(true);
      const recaptchaToken = await validateCaptcha();
      await createProfileService({
        payload: {
          ...formik.values,
          nationality: formik.values.nationality.value || null,
          specialization: formik.values.specialization.value || null,
          facebookID: formik.values.facebookID.toLowerCase(),
          recaptchaToken,
        },
      });
      history.push('/');
      formik.resetForm();
      setDisabled(false);
      toast.success('Мы получили Вашу анкету. До скорой связи!');
    } catch (e) {
      console.error(e);
      setDisabled(false);
      toast.error(
        (e.data && e.data.message && e.data.message) || 'Что-то пошло не так...'
      );
    }
  };

  return (
    <main className={style.main}>
      {/* <pre
        style={{
          position: 'absolute',
          top: '100px',
          left: '10px',
          zIndex: 2100,
        }}
      >
        {JSON.stringify(formik, undefined, 2)}
      </pre> */}

      <div className={style.head}>
        <h1 className={style.header}>Анкета</h1>
        <Link to="/profile/passport/" className={style.addPassport}>
          Паспорт
        </Link>
      </div>
      <p className={style.desc}>
        <span>
          Для рассмотрения заявки, мы просим Вас сообщить нам следующие данные:
          ФИО, дату рождения, специализацию, параметры тела, фото, промо-видео,
          контактные данные и соц. сети и паспортные данные (не обязательный
          пункт).
        </span>
        <span>
          <b>
            Предоставленные данные обязательно должны совпадать с
            ЗАГРАНпаспортом, по которому Вы планируете выезжать за границу!
          </b>
        </span>
        <span>
          Если у Вас возникнут сложности или вопросы, пишите нам в Telegram-бот{' '}
          <a
            href="https://t.me/eraartagencybot"
            target="_blank"
            rel="noreferrer"
          >
            @EraArtAgencyBot
          </a>
        </span>
      </p>
      <form className={style.form}>
        <div className={style.field}>
          <h2 className={style.fieldName}>Личные данные</h2>
          <div className={style.inputs}>
            <Input
              name="lastName"
              formik={formik}
              label="Фамилия"
              type="text"
              placeholder="Иванов"
              uppercase
            />
            <Input
              name="firstName"
              formik={formik}
              label="Имя"
              type="text"
              placeholder="Иван"
              uppercase
            />
            <Input
              name="middleName"
              formik={formik}
              label="Отчество"
              type="text"
              placeholder="Иванович"
              uppercase
            />
          </div>
          <div className={style.inputs}>
            <Input
              name="passportFirstName"
              formik={formik}
              label="Name (латинскими, как в паспорте)"
              type="text"
              placeholder="Ivan"
              uppercase
            />
            <Input
              name="passportLastName"
              formik={formik}
              label="Surname (латинскими, как в паспорте)"
              type="text"
              placeholder="Ivanov"
              uppercase
            />
            <Select
              name="nationality"
              label="Гражданство"
              formik={formik}
              type="text"
              placeholder="Выбрать"
              items={countries}
            />
          </div>
          <div className={style.inputs}>
            <Input
              name="birthDate"
              formik={formik}
              label="Дата рождения"
              type="text"
              placeholder="01-01-1991"
            />
          </div>
        </div>

        <div className={style.field}>
          <h2 className={style.fieldName}>Специализация</h2>
          <div className={style.inputs}>
            <Select
              name="specialization"
              formik={formik}
              type="text"
              placeholder="Выбрать"
              items={specs}
            />
          </div>
          <Input
            textarea
            name="about"
            formik={formik}
            label="Спецификация"
            type="text"
            placeholder="Опишите в свободной форме Ваши умения, навыки, опыт, возможности..."
          />
        </div>

        <div className={style.field}>
          <h2 className={style.fieldName}>Параметры тела</h2>
          <div className={style.inputs}>
            <Input
              name="height"
              formik={formik}
              label="Рост (см)"
              type="number"
              placeholder="187"
            />
            <Input
              name="weigth"
              formik={formik}
              label="Вес (кг)"
              type="number"
              placeholder="65"
            />
            <Input
              name="shoeSize"
              formik={formik}
              label="Размер обуви"
              type="number"
              placeholder="43"
            />
          </div>
        </div>

        <div className={style.field}>
          <h2 className={style.fieldName}>YouTube промо-видео</h2>
          <Input
            name="youtube1"
            formik={formik}
            label="Ссылка 1*"
            type="text"
            placeholder="https://www.youtube.com/watch?v=uBsW424P8Fw"
          />
          <Input
            name="youtube2"
            formik={formik}
            label="Ссылка 2"
            type="text"
            placeholder="https://www.youtube.com/watch?v=uBsW424P8Fw"
          />
          <Input
            name="youtube3"
            formik={formik}
            label="Ссылка 3"
            type="text"
            placeholder="https://www.youtube.com/watch?v=uBsW424P8Fw"
          />
        </div>

        <div className={style.field}>
          <h2 className={style.fieldName}>Фото для документов</h2>
          <PhotoUpload
            name="photoFace"
            formik={formik}
            handleFile={(file) => formik.setFieldValue('photoFace', file)}
          />
        </div>
        <div className={style.field}>
          <h2 className={style.fieldName}>Фото в полный рост</h2>
          <PhotoUpload
            name="photoBody"
            formik={formik}
            handleFile={(file) => formik.setFieldValue('photoBody', file)}
          />
        </div>

        <div className={style.field}>
          <h2 className={style.fieldName}>Контакты</h2>
          <span className={style.info}>
            Обязательны: телефон, email, facebook и минимум один сервис из
            остальных предложенных.
          </span>
          <div className={style.inputsTwoOne}>
            <Input
              name="facebookID"
              formik={formik}
              label="Профиль на Facebook, Одноклассники, VK (одна ссылка)*"
              type="text"
              placeholder="facebook.com/Era-Art-Ltd-103860308051239"
            />
            <Input
              name="email"
              formik={formik}
              label="E-mail*"
              type="text"
              placeholder="eraart@eraart.com"
              lowercase
            />
          </div>
          <div className={style.inputs}>
            <Input
              name="phone"
              formik={formik}
              label="Телефон*"
              type="text"
              placeholder="+34635686618"
            />
            <Input
              name="skype"
              formik={formik}
              label="Skype"
              type="text"
              placeholder="artagency777"
            />
            <Input
              name="telegram"
              formik={formik}
              label="Telegram"
              type="text"
              placeholder="artagency777"
            />
          </div>
          <div className={style.inputs}>
            <Input
              name="whatsapp"
              formik={formik}
              label="Whatsapp"
              type="text"
              placeholder="+34635686618"
            />
            <Input
              name="viber"
              formik={formik}
              label="Viber"
              type="text"
              placeholder="+34635686618"
            />
            <Input
              name="wechat"
              formik={formik}
              label="WeChat"
              type="text"
              placeholder="artagency777"
            />
          </div>
          <div className={style.inputs}>
            <Input
              name="imo"
              formik={formik}
              label="imo"
              type="text"
              placeholder="+34635686618"
            />
            <Input
              name="botim"
              formik={formik}
              label="BOTIM"
              type="text"
              placeholder="+34635686618"
            />
            <Input
              name="line"
              formik={formik}
              label="LINE"
              type="text"
              placeholder="+34635686618"
            />
          </div>
        </div>

        <div className={style.gray}>
          <div className={style.field}>
            <div className={style.passportHeader}>
              <h2
                className={cn(
                  style.fieldName,
                  !formik.values.withPassport && style.disabled
                )}
              >
                Паспортные данные
              </h2>
              <ModalPassport
                withPassport={formik.values.withPassport}
                setWithPassport={(val) => {
                  formik.setFieldValue('withPassport', val);
                  formik.setFieldValue('issueDate', '');
                  formik.setFieldValue('expiryDate', '');
                  formik.setFieldValue('passport', null);
                }}
              />
            </div>
            {formik.values.withPassport && (
              <div className={style.inputs}>
                <Input
                  name="passportNumber"
                  formik={formik}
                  label="Номер паспорта"
                  type="text"
                  placeholder="AB1234567"
                  uppercase
                />
                <Input
                  name="issueDate"
                  formik={formik}
                  label="Дaта выдачи"
                  type="text"
                  placeholder="01-01-1991"
                />
                <Input
                  name="expiryDate"
                  formik={formik}
                  label="Срок действия (до)"
                  type="text"
                  placeholder="01-01-1991"
                />
              </div>
            )}
          </div>

          <div className={style.field}>
            <h2
              className={cn(
                style.fieldName,
                !formik.values.withPassport && style.disabled
              )}
            >
              Фото или скан паспорта
            </h2>
            {formik.values.withPassport && (
              <PhotoUpload
                name="passport"
                formik={formik}
                handleFile={(file) => formik.setFieldValue('passport', file)}
              />
            )}
          </div>
        </div>
      </form>

      <div className={style.submitWrapper}>
        {/* <button
          type="button"
          onClick={handleToggleGetNews}
          className={style.subscription}
        >
          <div
            className={cn(
              style.checkbox,
              !formik.values['getNews'] && style.checkboxHide
            )}
          />
          <span
            className={cn(
              style.checkboxText,
              !formik.values['getNews'] && style.checkboxTextLine
            )}
          >
            я хочу получать почту о новых вакансиях и новостях компании
          </span>
        </button> */}
        <button
          type="submit"
          onClick={handleSubmit}
          className={style.actionBtn}
          disabled={isDisabled}
        >
          {isDisabled ? 'Отправка...' : 'Отправить'}
          {isDisabled && (
            <div className={style.loader}>
              <div />
            </div>
          )}
        </button>
        <p className={style.disclaimer}>
          Ваши данные хранятся на защищенном сервере и используются ТОЛЬКО для
          оформления документов
        </p>
      </div>
    </main>
  );
};

export default Profile;
