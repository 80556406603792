import aboutImage from './homePage/who-we-are.png';
import building from './homePage/building.png';
import forbs from './homePage/forbs.png';
import tengHsiung from './homePage/teng-hsiung.png';
import stadium from './homePage/stadium.png';
import parkLong from './homePage/parkLong.png';
import parkShort from './homePage/parkShort.png';
import mapImg from './homePage/map.jpg';

import igor from './homePage/igor.png';
import vadim from './homePage/vadim.png';
import andrei from './homePage/andrei.png';
import kristina from './homePage/kristina.png';
import natalia from './homePage/natalia.png';

import team from './homePage/team.png';

import wave from './homePage/wave.svg';
import shows from './homePage/shows.svg';
import dolphins from './homePage/dolphins.svg';
import fest from './homePage/fest.svg';

import FGlogo from './homePage/FGlogo.svg';

import teng2 from './homePage/teng2.png';

import artists1 from './homePage/artists1.jpeg';

import live1 from './lifestyle/live1.jpeg';
import live2 from './lifestyle/live2.jpeg';
import live3 from './lifestyle/live3.jpeg';
import live4 from './lifestyle/live4.jpeg';
import live5 from './lifestyle/live5.jpeg';
import live6 from './lifestyle/live6.jpeg';
import live8 from './lifestyle/live8.jpeg';
import live9 from './lifestyle/live9.jpeg';
import live10 from './lifestyle/live10.jpeg';
import live11 from './lifestyle/live11.jpeg';

import show1 from './show/show1.jpeg';
import show2 from './show/show2.jpeg';
import show3 from './show/show3.jpeg';
import show4 from './show/show4.jpeg';
import show5 from './show/show5.jpeg';
import show6 from './show/show6.jpeg';
import show7 from './show/show7.jpeg';
import show8 from './show/show8.jpeg';
import show9 from './show/show9.jpeg';
import show10 from './show/show10.jpeg';

const placeImages = [
  live6,
  live8,
  live9,
  live10,
  live11,
  live5,
  live2,
  live3,
  live1,
];

const showImages = [
  show1,
  show2,
  show3,
  show5,
  show4,
  show6,
  show7,
  show8,
  show9,
  show10,
];

export {
  aboutImage,
  building,
  forbs,
  tengHsiung,
  stadium,
  parkLong,
  parkShort,
  mapImg,
  igor,
  vadim,
  andrei,
  kristina,
  natalia,
  team,
  fest,
  dolphins,
  shows,
  wave,
  FGlogo,
  teng2,
  show10,
  live4,
  show7,
  show1,
  artists1,
  placeImages,
  showImages,
};
