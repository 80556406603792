import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import faceExample from '../../img/faceExample.jpg';
import bodyExample from '../../img/bodyExample.jpg';
import passExample from '../../img/passExample.jpg';

import style from './PhotoUpload.module.css';

const examples = {
  photoFace: <img src={faceExample} alt={'пример фото лица'} />,
  photoBody: <img src={bodyExample} alt={'пример фото тела'} />,
  passport: <img src={passExample} alt={'пример скана паспорта'} />,
};

const infoTexts = {
  photoBody: (
    <p className={style.infoText}>
      - свободная фотография, на которой Вы изображены в полный рост;
      <br /> - на фото изображены только Вы;
      <br /> - размер файла не превышает 1МБ.
    </p>
  ),
  photoFace: (
    <p className={style.infoText}>
      - фото выполнено на светлом фоне;
      <br /> - лицо не должно быть прикрыто волосами;
      <br /> - по возможности, без очков;
      <br /> - размер файла не превышает 1МБ.
    </p>
  ),
  passport: (
    <p className={style.infoText}>
      - фото или скан полного разворота паспорта (где расположено фото);
      <br /> - все данные должны быть видны и читабельны;
      <br /> - размер файла не превышает 1МБ.
    </p>
  ),
};

const PhotoUpload = ({ handleFile, formik, name }) => {
  const hiddenFileInput = useRef(null);
  const [file, setFile] = useState(null);
  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const hasError = formik.errors[name] && formik.touched[name];

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    formik.setFieldTouched(name, true);
    setFile(fileUploaded ? URL.createObjectURL(fileUploaded) : null);
    handleFile(fileUploaded);
  };

  const handleRemove = (event) => {
    event.target.value = '';
    setFile(null);
    handleFile(null);
  };

  return (
    <div className={style.container}>
      <div className={style.frames}>
        {file ? (
          <div className={style.imgContainer}>
            <img src={file} alt={name} />
            <button
              type="button"
              className={style.btnRemove}
              onClick={handleRemove}
            >
              удалить
            </button>
          </div>
        ) : (
          <button
            type="button"
            className={cn(style.btn, hasError && style.btnError)}
            onClick={handleClick}
          >
            <span>Загрузить</span>
          </button>
        )}

        {!!examples[name] && (
          <div className={style.imageFrame}>
            {examples[name]}
            <span>пример</span>
          </div>
        )}
      </div>

      <input
        accept="image/jpg, image/jpeg, image/png"
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }}
        name={name}
      />
      <span className={style.errorMobile}>
        {hasError ? formik.errors[name] : ''}
      </span>
      <div className={style.info}>
        {infoTexts[name] || <div />}
        <span className={style.errorDesktop}>
          {hasError ? formik.errors[name] : ''}
        </span>
      </div>
    </div>
  );
};

PhotoUpload.propTypes = {
  handleFile: PropTypes.func.isRequired,
  formik: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
};

export default PhotoUpload;
