import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';

import { getVacanciesService } from './server/vacanciesAPI';

import Home from './pages/Home/Home';
import Gallery from './pages/Gallery/Gallery';
import Profile from './pages/Profile/Profile';
import ProfilePassport from './pages/ProfilePassport/ProfilePassport';
import Vacancies from './pages/Vacancies/Vacancies';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';

const App = () => {
  const { pathname } = useLocation();
  const [vacancies, setVacancies] = useState(null);

  const getProfiles = async () => {
    try {
      const data = await getVacanciesService();
      setVacancies(data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    getProfiles();
  }, []);

  return (
    <>
      <Header />
      <Switch>
        <Route
          exact
          path="/"
          render={(p) => (
            <Home {...p} hasVacancies={vacancies && !!vacancies.length} />
          )}
        />
        <Route exact path="/gallery" component={Gallery} />
        <Route exact path="/profile" component={Profile} />
        <Route exact path="/profile/passport" component={ProfilePassport} />
        <Route
          exact
          path="/vacancies"
          render={(p) => <Vacancies {...p} vacancies={vacancies} />}
        />
        <Redirect to="/" />
      </Switch>
      <Footer />
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default App;
